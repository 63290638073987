import React from "react";
import FeatureRegister from "./FeatureRegister";
import AccordionOne from "./accordion/AccordionOne";

const FaqOne = () => {
  return (
    <>
      {/*faq-area start*/}
  <section className="bandwidth" id="Contactus">
        <div className='container p-sm-0'>
          <div className='row'>
            <div className='col-xl-6 col-lg-5 order-lg-2'>
              <div className='thumb text-center mb-4 mb-lg-0'>
            <FeatureRegister />
              </div>
            </div>
            <div className='col-xl-6 col-lg-7 order-lg-1'>
              <div className='section-title'>
                         <h2 className='title'>Frequently Asked Question</h2>
                {/* <p className='content'>
                  Progressively utilize integrated bandwidth via vertical
                  relationships. Objectively impact emerging human capital via
                  tactical methods of empowerment.
                </p> */}
              </div>
             <AccordionOne />
            </div>
          </div>
        </div>
        </section>
      {/*faq-area end*/}
    </>
  );
};

export default FaqOne;
