import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import NewsletterOne from "./NewsletterOne";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import footerlogo from "../../assets/images/footerlogo.png";
import location from "../../assets/images/location.png";

const footerIntem =  footerOne[0];
const footerIntemOne =  footerOne[1];
const footerIntemTwo =  footerOne[2];
const footerIntemThree =  footerOne[3];
const footerIntemFour =  footerOne[4];
const footerIntemFive =  footerOne[5];

const indexOneLink = (footerIntemOne.quicklink);
const indexTwoLink = (footerIntemTwo.quicklink);
const indexThreeLink = (footerIntemThree.quicklink);

const FooterFour = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default no-border">
                     
                <section className="copyright-area copyright-style-one no-border">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-12">
                            <Link to="/">
                              <img src={footerlogo} alt='footerlogo' className='footerlogo' />
                              </Link>
                              <hr />
                              <div className='add'>
                              <p>Dibon building, ITC-2 , sector-67 Mohali, 160062</p>
                            <p><a href="mailto:contact@mvdservices.co" >contact@mvdservices.co</a></p>
                         <p><a href="tel:8729056066" >+91 8729056066</a></p>
                            </div>
                            </div>
                            </div>
                                                     
                        </div>
                    </section>
           
            </footer>
            <ScrollTop />
        </>
    )
}

export default FooterFour;
