import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} >
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                What services does MVD Marketing offer?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>MVD Marketing offers a range of services including marketing for logistics businesses, warehousing solutions, transportation services (full truckload, less than container load, flatbed freight, intermodal shipping), dispatch services, transload services, brokerage, and more.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                How experienced is the team at MVD Marketing?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>The team at MVD Marketing consists of seasoned professionals with extensive experience in the logistics industry. They bring in-depth knowledge and a deep understanding of marketing dynamics to develop effective strategies for clients.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                How can MVD Marketing help optimize my logistics business?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>MVD Marketing can optimize your logistics business through innovative marketing approaches, cost optimization strategies, efficient supply chain management, and personalized solutions tailored to your specific needs. Their expertise can help improve visibility, profitability, and customer satisfaction.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                Does MVD Marketing provide customer support?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, MVD Marketing is dedicated to providing exceptional customer support. Their support team is available 24/7 to address inquiries, provide guidance, and ensure a seamless experience throughout the partnership.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                Can MVD Marketing handle specialized shipments?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>Yes, MVD Marketing offers specialized services such as flatbed freight solutions for oversized or heavy cargo. They can also handle transload services, ensuring efficient transfer between different modes of transportation.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                How does MVD Marketing ensure reliability and timeliness?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>MVD Marketing understands the importance of reliable and timely logistics. They prioritize efficient transportation planning, strategic decision-making, and leverage cutting-edge technology to ensure on-time delivery of shipments.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                Does MVD Marketing offer customized solutions?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                    <Card.Body>Yes, MVD Marketing understands that every logistics business is unique. They offer personalized solutions tailored to the specific needs of each client, ensuring maximum efficiency and success.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                What technologies does MVD Marketing utilize?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                    <Card.Body>MVD Marketing leverages cutting-edge technologies and tools to streamline operations and enhance visibility. They provide real-time tracking and reporting, empowering clients with actionable insights for improved decision-making.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                How can I get started with MVD Marketing's services?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                    <Card.Body>To get started with MVD Marketing, simply reach out to their team through their website or contact information. They will guide you through the process and discuss the specific requirements of your logistics business.</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
export default AccordionOne

