import React from 'react'
import HeaderTwo from '../common/header/HeaderTwo';
import FooterFour from '../common/footer/FooterFour';
import thanks from "../assets/images/thanks.png";

const Thankyou = () => {
    return (
<>
  <main className="page-wrapper">
                
  <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-transparent" />
  <section className='thankyou'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-md-12 co-lg-12 col-12'>
                <img src={thanks} className='thanks' alt='thanks' />
            </div>
        </div>
    </div>
  </section>
 <FooterFour />
            </main>
        </>
    )
}
export default Thankyou;