import React from "react";
import solultion from "../assets/images/solultion.png";
const SolutionOne = () => {
  return (
    <>
      {/* Start Solution area  */}

      <div className='solution_area text-center' id="solutions">
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title   text-center'>
                          <h2 className='title'>Why Choose Us?</h2>
                          <br />
                <p> Choose MVD Marketing for your logistics business because we offer unmatched expertise in the industry. Our innovative strategies and cutting-edge techniques will
give you a competitive edge, fuel growth, and maximize your brand's visibility and profitability.
                </p>
              </div>
            </div>
          </div>
          <div className='row  justify-content-center'>
          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
              <div className='solution-item'>
                <div className='solution-image'>
                <img src={solultion}  className='img-fluid'
                    alt='solution image' />
                  
                </div>
                <h4 className='title'>Extensive Industry Expertise</h4>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
              <div className='solution-item'>
                <div className='solution-image'>
                <img src={solultion}  className='img-fluid'
                    alt='solution image' />
                </div>
                <h4 className='title'>Innovative Marketing Approach</h4>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
              <div className='solution-item'>
                <div className='solution-image'>
                <img src={solultion}  className='img-fluid'
                    alt='solution image' />
                </div>
                <h4 className='title'>Comprehensive Logistics Services</h4>
              </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
              <div className='solution-item'>
                <div className='solution-image'>
                <img src={solultion}  className='img-fluid'
                    alt='solution image' />
                </div>
                <h4 className='title'>Dedicated Customer Support</h4>
              </div>
            </div>
         
          </div>
        </div>
      </div>
      {/* End Solution area  */}
    </>
  );
};

export default SolutionOne;
